@font-face {
    font-family: 'unicons';
    src: url('../../../../fonts/unicons.eot?34404611');
    src:
        url('../../../../fonts/unicons.eot?34404611#iefix') format('embedded-opentype'),
        url('../../../../fonts/unicons.woff2?34404611') format('woff2'),
        url('../../../../fonts/unicons.woff?34404611') format('woff'),
        url('../../../../fonts/unicons.ttf?34404611') format('truetype'),
        url('../../../../fonts/unicons.svg?34404611#unicons') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../font/unicons.svg?34404611#unicons') format('svg');
  }
}
*/

[class^='uil-']:before,
[class*=' uil-']:before {
    font-family: 'unicons';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.uil-0-plus:before {
    content: '\e800';
} /* '' */
.uil-10-plus:before {
    content: '\e801';
} /* '' */
.uil-12-plus:before {
    content: '\e802';
} /* '' */
.uil-13-plus:before {
    content: '\e803';
} /* '' */
.uil-16-plus:before {
    content: '\e804';
} /* '' */
.uil-17-plus:before {
    content: '\e805';
} /* '' */
.uil-18-plus:before {
    content: '\e806';
} /* '' */
.uil-21-plus:before {
    content: '\e807';
} /* '' */
.uil-3-plus:before {
    content: '\e808';
} /* '' */
.uil-500px:before {
    content: '\e809';
} /* '' */
.uil-6-plus:before {
    content: '\e80a';
} /* '' */
.uil-abacus:before {
    content: '\e80b';
} /* '' */
.uil-accessible-icon-alt:before {
    content: '\e80c';
} /* '' */
.uil-adjust-alt:before {
    content: '\e80d';
} /* '' */
.uil-adjust-circle:before {
    content: '\e80e';
} /* '' */
.uil-adjust-half:before {
    content: '\e80f';
} /* '' */
.uil-adjust:before {
    content: '\e810';
} /* '' */
.uil-adobe:before {
    content: '\e811';
} /* '' */
.uil-airplay:before {
    content: '\e812';
} /* '' */
.uil-align-alt:before {
    content: '\e813';
} /* '' */
.uil-align-center-alt:before {
    content: '\e814';
} /* '' */
.uil-align-center-h:before {
    content: '\e815';
} /* '' */
.uil-align-center-justify:before {
    content: '\e816';
} /* '' */
.uil-align-center-v:before {
    content: '\e817';
} /* '' */
.uil-align-center:before {
    content: '\e818';
} /* '' */
.uil-align-justify:before {
    content: '\e819';
} /* '' */
.uil-align-left-justify:before {
    content: '\e81a';
} /* '' */
.uil-align-left:before {
    content: '\e81b';
} /* '' */
.uil-align-letter-right:before {
    content: '\e81c';
} /* '' */
.uil-align-right-justify:before {
    content: '\e81d';
} /* '' */
.uil-align-right:before {
    content: '\e81e';
} /* '' */
.uil-align:before {
    content: '\e81f';
} /* '' */
.uil-ambulance:before {
    content: '\e820';
} /* '' */
.uil-analysis:before {
    content: '\e821';
} /* '' */
.uil-analytics:before {
    content: '\e822';
} /* '' */
.uil-anchor:before {
    content: '\e823';
} /* '' */
.uil-android-phone-slash:before {
    content: '\e824';
} /* '' */
.uil-angle-double-down:before {
    content: '\e825';
} /* '' */
.uil-angle-double-left:before {
    content: '\e826';
} /* '' */
.uil-angle-double-right:before {
    content: '\e827';
} /* '' */
.uil-angle-double-up:before {
    content: '\e828';
} /* '' */
.uil-angle-down:before {
    content: '\e829';
} /* '' */
.uil-angle-left:before {
    content: '\e82a';
} /* '' */
.uil-angle-right-b:before {
    content: '\e82b';
} /* '' */
.uil-angle-right:before {
    content: '\e82c';
} /* '' */
.uil-angle-up:before {
    content: '\e82d';
} /* '' */
.uil-angry:before {
    content: '\e82e';
} /* '' */
.uil-ankh:before {
    content: '\e82f';
} /* '' */
.uil-annoyed-alt:before {
    content: '\e830';
} /* '' */
.uil-annoyed:before {
    content: '\e831';
} /* '' */
.uil-apple:before {
    content: '\e832';
} /* '' */
.uil-apps:before {
    content: '\e833';
} /* '' */
.uil-archive-alt:before {
    content: '\e834';
} /* '' */
.uil-archive:before {
    content: '\e835';
} /* '' */
.uil-archway:before {
    content: '\e836';
} /* '' */
.uil-arrow-break:before {
    content: '\e837';
} /* '' */
.uil-arrow-circle-down:before {
    content: '\e838';
} /* '' */
.uil-arrow-circle-left:before {
    content: '\e839';
} /* '' */
.uil-arrow-circle-right:before {
    content: '\e83a';
} /* '' */
.uil-arrow-circle-up:before {
    content: '\e83b';
} /* '' */
.uil-arrow-compress-h:before {
    content: '\e83c';
} /* '' */
.uil-arrow-down-left:before {
    content: '\e83d';
} /* '' */
.uil-arrow-down-right:before {
    content: '\e83e';
} /* '' */
.uil-arrow-down:before {
    content: '\e83f';
} /* '' */
.uil-arrow-from-right:before {
    content: '\e840';
} /* '' */
.uil-arrow-from-top:before {
    content: '\e841';
} /* '' */
.uil-arrow-growth:before {
    content: '\e842';
} /* '' */
.uil-arrow-left:before {
    content: '\e843';
} /* '' */
.uil-arrow-random:before {
    content: '\e844';
} /* '' */
.uil-arrow-resize-diagonal:before {
    content: '\e845';
} /* '' */
.uil-arrow-right:before {
    content: '\e846';
} /* '' */
.uil-arrow-to-bottom:before {
    content: '\e847';
} /* '' */
.uil-arrow-to-right:before {
    content: '\e848';
} /* '' */
.uil-arrow-up-left:before {
    content: '\e849';
} /* '' */
.uil-arrow-up-right:before {
    content: '\e84a';
} /* '' */
.uil-arrow-up:before {
    content: '\e84b';
} /* '' */
.uil-arrows-h-alt:before {
    content: '\e84c';
} /* '' */
.uil-arrows-h:before {
    content: '\e84d';
} /* '' */
.uil-arrows-left-down:before {
    content: '\e84e';
} /* '' */
.uil-arrows-maximize:before {
    content: '\e84f';
} /* '' */
.uil-arrows-merge:before {
    content: '\e850';
} /* '' */
.uil-arrows-resize-h:before {
    content: '\e851';
} /* '' */
.uil-arrows-resize-v:before {
    content: '\e852';
} /* '' */
.uil-arrows-resize:before {
    content: '\e853';
} /* '' */
.uil-arrows-right-down:before {
    content: '\e854';
} /* '' */
.uil-arrows-shrink-h:before {
    content: '\e855';
} /* '' */
.uil-arrows-shrink-v:before {
    content: '\e856';
} /* '' */
.uil-arrows-up-right:before {
    content: '\e857';
} /* '' */
.uil-arrows-v-alt:before {
    content: '\e858';
} /* '' */
.uil-arrows-v:before {
    content: '\e859';
} /* '' */
.uil-assistive-listening-systems:before {
    content: '\e85a';
} /* '' */
.uil-asterisk:before {
    content: '\e85b';
} /* '' */
.uil-at:before {
    content: '\e85c';
} /* '' */
.uil-atm-card:before {
    content: '\e85d';
} /* '' */
.uil-atom:before {
    content: '\e85e';
} /* '' */
.uil-auto-flash:before {
    content: '\e85f';
} /* '' */
.uil-award-alt:before {
    content: '\e860';
} /* '' */
.uil-award:before {
    content: '\e861';
} /* '' */
.uil-baby-carriage:before {
    content: '\e862';
} /* '' */
.uil-backpack:before {
    content: '\e863';
} /* '' */
.uil-backspace:before {
    content: '\e864';
} /* '' */
.uil-backward:before {
    content: '\e865';
} /* '' */
.uil-bag-alt:before {
    content: '\e866';
} /* '' */
.uil-bag-slash:before {
    content: '\e867';
} /* '' */
.uil-bag:before {
    content: '\e868';
} /* '' */
.uil-balance-scale:before {
    content: '\e869';
} /* '' */
.uil-ball:before {
    content: '\e86a';
} /* '' */
.uil-ban:before {
    content: '\e86b';
} /* '' */
.uil-bars:before {
    content: '\e86c';
} /* '' */
.uil-basketball-hoop:before {
    content: '\e86d';
} /* '' */
.uil-basketball:before {
    content: '\e86e';
} /* '' */
.uil-battery-bolt:before {
    content: '\e86f';
} /* '' */
.uil-battery-empty:before {
    content: '\e870';
} /* '' */
.uil-bed-double:before {
    content: '\e871';
} /* '' */
.uil-bed:before {
    content: '\e872';
} /* '' */
.uil-behance:before {
    content: '\e873';
} /* '' */
.uil-bell-school:before {
    content: '\e874';
} /* '' */
.uil-bell-slash:before {
    content: '\e875';
} /* '' */
.uil-bell:before {
    content: '\e876';
} /* '' */
.uil-bill:before {
    content: '\e877';
} /* '' */
.uil-bitcoin-circle:before {
    content: '\e878';
} /* '' */
.uil-bitcoin:before {
    content: '\e879';
} /* '' */
.uil-black-berry:before {
    content: '\e87a';
} /* '' */
.uil-bluetooth-b:before {
    content: '\e87b';
} /* '' */
.uil-bold:before {
    content: '\e87c';
} /* '' */
.uil-bolt-alt:before {
    content: '\e87d';
} /* '' */
.uil-bolt-slash:before {
    content: '\e87e';
} /* '' */
.uil-bolt:before {
    content: '\e87f';
} /* '' */
.uil-book-alt:before {
    content: '\e880';
} /* '' */
.uil-book-medical:before {
    content: '\e881';
} /* '' */
.uil-book-open:before {
    content: '\e882';
} /* '' */
.uil-book-reader:before {
    content: '\e883';
} /* '' */
.uil-book:before {
    content: '\e884';
} /* '' */
.uil-bookmark-full:before {
    content: '\e885';
} /* '' */
.uil-bookmark:before {
    content: '\e886';
} /* '' */
.uil-books:before {
    content: '\e887';
} /* '' */
.uil-boombox:before {
    content: '\e888';
} /* '' */
.uil-border-alt:before {
    content: '\e889';
} /* '' */
.uil-border-bottom:before {
    content: '\e88a';
} /* '' */
.uil-border-clear:before {
    content: '\e88b';
} /* '' */
.uil-border-horizontal:before {
    content: '\e88c';
} /* '' */
.uil-border-inner:before {
    content: '\e88d';
} /* '' */
.uil-border-left:before {
    content: '\e88e';
} /* '' */
.uil-border-out:before {
    content: '\e88f';
} /* '' */
.uil-border-right:before {
    content: '\e890';
} /* '' */
.uil-border-top:before {
    content: '\e891';
} /* '' */
.uil-border-vertical:before {
    content: '\e892';
} /* '' */
.uil-box:before {
    content: '\e893';
} /* '' */
.uil-briefcase-alt:before {
    content: '\e894';
} /* '' */
.uil-briefcase:before {
    content: '\e895';
} /* '' */
.uil-bright:before {
    content: '\e896';
} /* '' */
.uil-brightness-empty:before {
    content: '\e897';
} /* '' */
.uil-brightness-half:before {
    content: '\e898';
} /* '' */
.uil-brightness-low:before {
    content: '\e899';
} /* '' */
.uil-brightness-minus:before {
    content: '\e89a';
} /* '' */
.uil-brightness-plus:before {
    content: '\e89b';
} /* '' */
.uil-brightness:before {
    content: '\e89c';
} /* '' */
.uil-bring-bottom:before {
    content: '\e89d';
} /* '' */
.uil-bring-front:before {
    content: '\e89e';
} /* '' */
.uil-brush-alt:before {
    content: '\e89f';
} /* '' */
.uil-bug:before {
    content: '\e8a0';
} /* '' */
.uil-building:before {
    content: '\e8a1';
} /* '' */
.uil-bullseye:before {
    content: '\e8a2';
} /* '' */
.uil-bus-alt:before {
    content: '\e8a3';
} /* '' */
.uil-bus-school:before {
    content: '\e8a4';
} /* '' */
.uil-bus:before {
    content: '\e8a5';
} /* '' */
.uil-calcualtor:before {
    content: '\e8a6';
} /* '' */
.uil-calculator-alt:before {
    content: '\e8a7';
} /* '' */
.uil-calendar-alt:before {
    content: '\e8a8';
} /* '' */
.uil-calendar-slash:before {
    content: '\e8a9';
} /* '' */
.uil-calender:before {
    content: '\e8aa';
} /* '' */
.uil-calling:before {
    content: '\e8ab';
} /* '' */
.uil-camera-change:before {
    content: '\e8ac';
} /* '' */
.uil-camera-plus:before {
    content: '\e8ad';
} /* '' */
.uil-camera-slash:before {
    content: '\e8ae';
} /* '' */
.uil-camera:before {
    content: '\e8af';
} /* '' */
.uil-cancel:before {
    content: '\e8b0';
} /* '' */
.uil-capsule:before {
    content: '\e8b1';
} /* '' */
.uil-capture:before {
    content: '\e8b2';
} /* '' */
.uil-car-sideview:before {
    content: '\e8b3';
} /* '' */
.uil-car-slash:before {
    content: '\e8b4';
} /* '' */
.uil-car-wash:before {
    content: '\e8b5';
} /* '' */
.uil-car:before {
    content: '\e8b6';
} /* '' */
.uil-card-atm:before {
    content: '\e8b7';
} /* '' */
.uil-caret-right:before {
    content: '\e8b8';
} /* '' */
.uil-cart:before {
    content: '\e8b9';
} /* '' */
.uil-cell:before {
    content: '\e8ba';
} /* '' */
.uil-celsius:before {
    content: '\e8bb';
} /* '' */
.uil-chart-bar-alt:before {
    content: '\e8bc';
} /* '' */
.uil-chart-bar:before {
    content: '\e8bd';
} /* '' */
.uil-chart-down:before {
    content: '\e8be';
} /* '' */
.uil-chart-growth-alt:before {
    content: '\e8bf';
} /* '' */
.uil-chart-growth:before {
    content: '\e8c0';
} /* '' */
.uil-chart-line:before {
    content: '\e8c1';
} /* '' */
.uil-chart-pie-alt:before {
    content: '\e8c2';
} /* '' */
.uil-chart-pie:before {
    content: '\e8c3';
} /* '' */
.uil-chart:before {
    content: '\e8c4';
} /* '' */
.uil-chat-bubble-user:before {
    content: '\e8c5';
} /* '' */
.uil-chat-info:before {
    content: '\e8c6';
} /* '' */
.uil-chat:before {
    content: '\e8c7';
} /* '' */
.uil-check-circle:before {
    content: '\e8c8';
} /* '' */
.uil-check-square:before {
    content: '\e8c9';
} /* '' */
.uil-check:before {
    content: '\e8ca';
} /* '' */
.uil-circle-layer:before {
    content: '\e8cb';
} /* '' */
.uil-circle:before {
    content: '\e8cc';
} /* '' */
.uil-circuit:before {
    content: '\e8cd';
} /* '' */
.uil-clapper-board:before {
    content: '\e8ce';
} /* '' */
.uil-clipboard-alt:before {
    content: '\e8cf';
} /* '' */
.uil-clipboard-blank:before {
    content: '\e8d0';
} /* '' */
.uil-clipboard-notes:before {
    content: '\e8d1';
} /* '' */
.uil-clipboard:before {
    content: '\e8d2';
} /* '' */
.uil-clock-eight:before {
    content: '\e8d3';
} /* '' */
.uil-clock-five:before {
    content: '\e8d4';
} /* '' */
.uil-clock-nine:before {
    content: '\e8d5';
} /* '' */
.uil-clock-seven:before {
    content: '\e8d6';
} /* '' */
.uil-clock-ten:before {
    content: '\e8d7';
} /* '' */
.uil-clock-three:before {
    content: '\e8d8';
} /* '' */
.uil-clock-two:before {
    content: '\e8d9';
} /* '' */
.uil-clock:before {
    content: '\e8da';
} /* '' */
.uil-closed-captioning:before {
    content: '\e8db';
} /* '' */
.uil-cloud-block:before {
    content: '\e8dc';
} /* '' */
.uil-cloud-bookmark:before {
    content: '\e8dd';
} /* '' */
.uil-cloud-check:before {
    content: '\e8de';
} /* '' */
.uil-cloud-computing:before {
    content: '\e8df';
} /* '' */
.uil-cloud-data-connection:before {
    content: '\e8e0';
} /* '' */
.uil-cloud-database-tree:before {
    content: '\e8e1';
} /* '' */
.uil-cloud-download:before {
    content: '\e8e2';
} /* '' */
.uil-cloud-drizzle:before {
    content: '\e8e3';
} /* '' */
.uil-cloud-exclamation:before {
    content: '\e8e4';
} /* '' */
.uil-cloud-hail:before {
    content: '\e8e5';
} /* '' */
.uil-cloud-heart:before {
    content: '\e8e6';
} /* '' */
.uil-cloud-info:before {
    content: '\e8e7';
} /* '' */
.uil-cloud-lock:before {
    content: '\e8e8';
} /* '' */
.uil-cloud-meatball:before {
    content: '\e8e9';
} /* '' */
.uil-cloud-moon-hail:before {
    content: '\e8ea';
} /* '' */
.uil-cloud-moon-meatball:before {
    content: '\e8eb';
} /* '' */
.uil-cloud-moon-rain:before {
    content: '\e8ec';
} /* '' */
.uil-cloud-moon-showers:before {
    content: '\e8ed';
} /* '' */
.uil-cloud-moon:before {
    content: '\e8ee';
} /* '' */
.uil-cloud-question:before {
    content: '\e8ef';
} /* '' */
.uil-cloud-rain-sun:before {
    content: '\e8f0';
} /* '' */
.uil-cloud-rain:before {
    content: '\e8f1';
} /* '' */
.uil-cloud-redo:before {
    content: '\e8f2';
} /* '' */
.uil-cloud-set:before {
    content: '\e8f3';
} /* '' */
.uil-cloud-share:before {
    content: '\e8f4';
} /* '' */
.uil-cloud-shield:before {
    content: '\e8f5';
} /* '' */
.uil-cloud-showers-alt:before {
    content: '\e8f6';
} /* '' */
.uil-cloud-showers-heavy:before {
    content: '\e8f7';
} /* '' */
.uil-cloud-showers:before {
    content: '\e8f8';
} /* '' */
.uil-cloud-slash:before {
    content: '\e8f9';
} /* '' */
.uil-cloud-sun-hail:before {
    content: '\e8fa';
} /* '' */
.uil-cloud-sun-meatball:before {
    content: '\e8fb';
} /* '' */
.uil-cloud-sun-rain-alt:before {
    content: '\e8fc';
} /* '' */
.uil-cloud-sun-rain:before {
    content: '\e8fd';
} /* '' */
.uil-cloud-sun-tear:before {
    content: '\e8fe';
} /* '' */
.uil-cloud-sun:before {
    content: '\e8ff';
} /* '' */
.uil-cloud-times:before {
    content: '\e900';
} /* '' */
.uil-cloud-unlock:before {
    content: '\e901';
} /* '' */
.uil-cloud-upload:before {
    content: '\e902';
} /* '' */
.uil-cloud-wifi:before {
    content: '\e903';
} /* '' */
.uil-cloud-wind:before {
    content: '\e904';
} /* '' */
.uil-cloud:before {
    content: '\e905';
} /* '' */
.uil-clouds:before {
    content: '\e906';
} /* '' */
.uil-club:before {
    content: '\e907';
} /* '' */
.uil-code:before {
    content: '\e908';
} /* '' */
.uil-coffee:before {
    content: '\e909';
} /* '' */
.uil-cog:before {
    content: '\e90a';
} /* '' */
.uil-coins:before {
    content: '\e90b';
} /* '' */
.uil-columns:before {
    content: '\e90c';
} /* '' */
.uil-comment-alt-block:before {
    content: '\e90d';
} /* '' */
.uil-comment-alt-chart-lines:before {
    content: '\e90e';
} /* '' */
.uil-comment-alt-check:before {
    content: '\e90f';
} /* '' */
.uil-comment-alt-dots:before {
    content: '\e910';
} /* '' */
.uil-comment-alt-download:before {
    content: '\e911';
} /* '' */
.uil-comment-alt-edit:before {
    content: '\e912';
} /* '' */
.uil-comment-alt-exclamation:before {
    content: '\e913';
} /* '' */
.uil-comment-alt-heart:before {
    content: '\e914';
} /* '' */
.uil-comment-alt-image:before {
    content: '\e915';
} /* '' */
.uil-comment-alt-info:before {
    content: '\e916';
} /* '' */
.uil-comment-alt-lines:before {
    content: '\e917';
} /* '' */
.uil-comment-alt-lock:before {
    content: '\e918';
} /* '' */
.uil-comment-alt-medical:before {
    content: '\e919';
} /* '' */
.uil-comment-alt-message:before {
    content: '\e91a';
} /* '' */
.uil-comment-alt-notes:before {
    content: '\e91b';
} /* '' */
.uil-comment-alt-plus:before {
    content: '\e91c';
} /* '' */
.uil-comment-alt-question:before {
    content: '\e91d';
} /* '' */
.uil-comment-alt-redo:before {
    content: '\e91e';
} /* '' */
.uil-comment-alt-search:before {
    content: '\e91f';
} /* '' */
.uil-comment-alt-share:before {
    content: '\e920';
} /* '' */
.uil-comment-alt-shield:before {
    content: '\e921';
} /* '' */
.uil-comment-alt-slash:before {
    content: '\e922';
} /* '' */
.uil-comment-alt-upload:before {
    content: '\e923';
} /* '' */
.uil-comment-alt-verify:before {
    content: '\e924';
} /* '' */
.uil-comment-alt:before {
    content: '\e925';
} /* '' */
.uil-comment-block:before {
    content: '\e926';
} /* '' */
.uil-comment-chart-line:before {
    content: '\e927';
} /* '' */
.uil-comment-check:before {
    content: '\e928';
} /* '' */
.uil-comment-dots:before {
    content: '\e929';
} /* '' */
.uil-comment-download:before {
    content: '\e92a';
} /* '' */
.uil-comment-edit:before {
    content: '\e92b';
} /* '' */
.uil-comment-exclamation:before {
    content: '\e92c';
} /* '' */
.uil-comment-heart:before {
    content: '\e92d';
} /* '' */
.uil-comment-image:before {
    content: '\e92e';
} /* '' */
.uil-comment-info-alt:before {
    content: '\e92f';
} /* '' */
.uil-comment-info:before {
    content: '\e930';
} /* '' */
.uil-comment-lines:before {
    content: '\e931';
} /* '' */
.uil-comment-lock:before {
    content: '\e932';
} /* '' */
.uil-comment-medical:before {
    content: '\e933';
} /* '' */
.uil-comment-message:before {
    content: '\e934';
} /* '' */
.uil-comment-notes:before {
    content: '\e935';
} /* '' */
.uil-comment-plus:before {
    content: '\e936';
} /* '' */
.uil-comment-question:before {
    content: '\e937';
} /* '' */
.uil-comment-redo:before {
    content: '\e938';
} /* '' */
.uil-comment-search:before {
    content: '\e939';
} /* '' */
.uil-comment-share:before {
    content: '\e93a';
} /* '' */
.uil-comment-shield:before {
    content: '\e93b';
} /* '' */
.uil-comment-slash:before {
    content: '\e93c';
} /* '' */
.uil-comment-upload:before {
    content: '\e93d';
} /* '' */
.uil-comment-verify:before {
    content: '\e93e';
} /* '' */
.uil-comment:before {
    content: '\e93f';
} /* '' */
.uil-comments-alt:before {
    content: '\e940';
} /* '' */
.uil-comments:before {
    content: '\e941';
} /* '' */
.uil-commnet-alt-slash:before {
    content: '\e942';
} /* '' */
.uil-compact-disc:before {
    content: '\e943';
} /* '' */
.uil-compass:before {
    content: '\e944';
} /* '' */
.uil-compress-alt-left:before {
    content: '\e945';
} /* '' */
.uil-compress-alt:before {
    content: '\e946';
} /* '' */
.uil-compress-arrows:before {
    content: '\e947';
} /* '' */
.uil-compress-lines:before {
    content: '\e948';
} /* '' */
.uil-compress-point:before {
    content: '\e949';
} /* '' */
.uil-compress-v:before {
    content: '\e94a';
} /* '' */
.uil-compress:before {
    content: '\e94b';
} /* '' */
.uil-computer-mouse:before {
    content: '\e94c';
} /* '' */
.uil-confused:before {
    content: '\e94d';
} /* '' */
.uil-constructor:before {
    content: '\e94e';
} /* '' */
.uil-copy-alt:before {
    content: '\e94f';
} /* '' */
.uil-copy-landscape:before {
    content: '\e950';
} /* '' */
.uil-copy:before {
    content: '\e951';
} /* '' */
.uil-copyright:before {
    content: '\e952';
} /* '' */
.uil-corner-down-left:before {
    content: '\e953';
} /* '' */
.uil-corner-down-right-alt:before {
    content: '\e954';
} /* '' */
.uil-corner-down-right:before {
    content: '\e955';
} /* '' */
.uil-corner-left-down:before {
    content: '\e956';
} /* '' */
.uil-corner-right-down:before {
    content: '\e957';
} /* '' */
.uil-corner-up-left-alt:before {
    content: '\e958';
} /* '' */
.uil-corner-up-left:before {
    content: '\e959';
} /* '' */
.uil-corner-up-right-alt:before {
    content: '\e95a';
} /* '' */
.uil-corner-up-right:before {
    content: '\e95b';
} /* '' */
.uil-creative-commons-pd-alt:before {
    content: '\e95c';
} /* '' */
.uil-creative-commons-pd:before {
    content: '\e95d';
} /* '' */
.uil-crockery:before {
    content: '\e95e';
} /* '' */
.uil-crop-alt-rotate-left:before {
    content: '\e95f';
} /* '' */
.uil-crop-alt-rotate-right:before {
    content: '\e960';
} /* '' */
.uil-crop-alt:before {
    content: '\e961';
} /* '' */
.uil-crosshair-alt:before {
    content: '\e962';
} /* '' */
.uil-crosshair:before {
    content: '\e963';
} /* '' */
.uil-crosshairs:before {
    content: '\e964';
} /* '' */
.uil-cube:before {
    content: '\e965';
} /* '' */
.uil-dashboard:before {
    content: '\e966';
} /* '' */
.uil-data-sharing:before {
    content: '\e967';
} /* '' */
.uil-database-alt:before {
    content: '\e968';
} /* '' */
.uil-database:before {
    content: '\e969';
} /* '' */
.uil-desert:before {
    content: '\e96a';
} /* '' */
.uil-desktop-alt-slash:before {
    content: '\e96b';
} /* '' */
.uil-desktop-alt:before {
    content: '\e96c';
} /* '' */
.uil-desktop-cloud-alt:before {
    content: '\e96d';
} /* '' */
.uil-desktop-slash:before {
    content: '\e96e';
} /* '' */
.uil-desktop:before {
    content: '\e96f';
} /* '' */
.uil-dialpad-alt:before {
    content: '\e970';
} /* '' */
.uil-dialpad:before {
    content: '\e971';
} /* '' */
.uil-diamond:before {
    content: '\e972';
} /* '' */
.uil-diary-alt:before {
    content: '\e973';
} /* '' */
.uil-diary:before {
    content: '\e974';
} /* '' */
.uil-dice-five:before {
    content: '\e975';
} /* '' */
.uil-dice-four:before {
    content: '\e976';
} /* '' */
.uil-dice-one:before {
    content: '\e977';
} /* '' */
.uil-dice-six:before {
    content: '\e978';
} /* '' */
.uil-dice-three:before {
    content: '\e979';
} /* '' */
.uil-dice-two:before {
    content: '\e97a';
} /* '' */
.uil-direction:before {
    content: '\e97b';
} /* '' */
.uil-directions:before {
    content: '\e97c';
} /* '' */
.uil-dizzy-meh:before {
    content: '\e97d';
} /* '' */
.uil-dna:before {
    content: '\e97e';
} /* '' */
.uil-document-layout-center:before {
    content: '\e97f';
} /* '' */
.uil-document-layout-left:before {
    content: '\e980';
} /* '' */
.uil-document-layout-right:before {
    content: '\e981';
} /* '' */
.uil-document:before {
    content: '\e982';
} /* '' */
.uil-dollar-alt:before {
    content: '\e983';
} /* '' */
.uil-dollar-sign-alt:before {
    content: '\e984';
} /* '' */
.uil-dollar-sign:before {
    content: '\e985';
} /* '' */
.uil-down-arrow:before {
    content: '\e986';
} /* '' */
.uil-download-alt:before {
    content: '\e987';
} /* '' */
.uil-dribbble:before {
    content: '\e988';
} /* '' */
.uil-drill:before {
    content: '\e989';
} /* '' */
.uil-dropbox:before {
    content: '\e98a';
} /* '' */
.uil-dumbbell:before {
    content: '\e98b';
} /* '' */
.uil-ear:before {
    content: '\e98c';
} /* '' */
.uil-edit-alt:before {
    content: '\e98d';
} /* '' */
.uil-edit:before {
    content: '\e98e';
} /* '' */
.uil-ellipsis-h:before {
    content: '\e98f';
} /* '' */
.uil-ellipsis-v:before {
    content: '\e990';
} /* '' */
.uil-emoji:before {
    content: '\e991';
} /* '' */
.uil-enter:before {
    content: '\e992';
} /* '' */
.uil-entry:before {
    content: '\e993';
} /* '' */
.uil-envelope-add:before {
    content: '\e994';
} /* '' */
.uil-envelope-alt:before {
    content: '\e995';
} /* '' */
.uil-envelope-block:before {
    content: '\e996';
} /* '' */
.uil-envelope-bookmark:before {
    content: '\e997';
} /* '' */
.uil-envelope-check:before {
    content: '\e998';
} /* '' */
.uil-envelope-download-alt:before {
    content: '\e999';
} /* '' */
.uil-envelope-download:before {
    content: '\e99a';
} /* '' */
.uil-envelope-edit:before {
    content: '\e99b';
} /* '' */
.uil-envelope-exclamation:before {
    content: '\e99c';
} /* '' */
.uil-envelope-heart:before {
    content: '\e99d';
} /* '' */
.uil-envelope-info:before {
    content: '\e99e';
} /* '' */
.uil-envelope-lock:before {
    content: '\e99f';
} /* '' */
.uil-envelope-minus:before {
    content: '\e9a0';
} /* '' */
.uil-envelope-open:before {
    content: '\e9a1';
} /* '' */
.uil-envelope-question:before {
    content: '\e9a2';
} /* '' */
.uil-envelope-receive:before {
    content: '\e9a3';
} /* '' */
.uil-envelope-redo:before {
    content: '\e9a4';
} /* '' */
.uil-envelope-search:before {
    content: '\e9a5';
} /* '' */
.uil-envelope-send:before {
    content: '\e9a6';
} /* '' */
.uil-envelope-share:before {
    content: '\e9a7';
} /* '' */
.uil-envelope-shield:before {
    content: '\e9a8';
} /* '' */
.uil-envelope-star:before {
    content: '\e9a9';
} /* '' */
.uil-envelope-times:before {
    content: '\e9aa';
} /* '' */
.uil-envelope-upload-alt:before {
    content: '\e9ab';
} /* '' */
.uil-envelope-upload:before {
    content: '\e9ac';
} /* '' */
.uil-envelope:before {
    content: '\e9ad';
} /* '' */
.uil-envelopes:before {
    content: '\e9ae';
} /* '' */
.uil-equal-circle:before {
    content: '\e9af';
} /* '' */
.uil-euro-circle:before {
    content: '\e9b0';
} /* '' */
.uil-euro:before {
    content: '\e9b1';
} /* '' */
.uil-exchange-alt:before {
    content: '\e9b2';
} /* '' */
.uil-exchange:before {
    content: '\e9b3';
} /* '' */
.uil-exclamation-circle:before {
    content: '\e9b4';
} /* '' */
.uil-exclamation-octagon:before {
    content: '\e9b5';
} /* '' */
.uil-exclamation-triangle:before {
    content: '\e9b6';
} /* '' */
.uil-exclude:before {
    content: '\e9b7';
} /* '' */
.uil-exit:before {
    content: '\e9b8';
} /* '' */
.uil-expand-alt:before {
    content: '\e9b9';
} /* '' */
.uil-expand-arrows-alt:before {
    content: '\e9ba';
} /* '' */
.uil-expand-arrows:before {
    content: '\e9bb';
} /* '' */
.uil-expand-from-corner:before {
    content: '\e9bc';
} /* '' */
.uil-expand-left:before {
    content: '\e9bd';
} /* '' */
.uil-expand-right:before {
    content: '\e9be';
} /* '' */
.uil-export:before {
    content: '\e9bf';
} /* '' */
.uil-exposure-alt:before {
    content: '\e9c0';
} /* '' */
.uil-exposure-increase:before {
    content: '\e9c1';
} /* '' */
.uil-external-link-alt:before {
    content: '\e9c2';
} /* '' */
.uil-eye-slash:before {
    content: '\e9c3';
} /* '' */
.uil-eye:before {
    content: '\e9c4';
} /* '' */
.uil-facebook-f:before {
    content: '\e9c5';
} /* '' */
.uil-facebook-messenger:before {
    content: '\e9c6';
} /* '' */
.uil-facebook:before {
    content: '\e9c7';
} /* '' */
.uil-fahrenheit:before {
    content: '\e9c8';
} /* '' */
.uil-fast-mail-alt:before {
    content: '\e9c9';
} /* '' */
.uil-fast-mail:before {
    content: '\e9ca';
} /* '' */
.uil-favorite:before {
    content: '\e9cb';
} /* '' */
.uil-feedback:before {
    content: '\e9cc';
} /* '' */
.uil-file-alt:before {
    content: '\e9cd';
} /* '' */
.uil-file-blank:before {
    content: '\e9ce';
} /* '' */
.uil-file-block-alt:before {
    content: '\e9cf';
} /* '' */
.uil-file-bookmark-alt:before {
    content: '\e9d0';
} /* '' */
.uil-file-check-alt:before {
    content: '\e9d1';
} /* '' */
.uil-file-check:before {
    content: '\e9d2';
} /* '' */
.uil-file-contract-dollar:before {
    content: '\e9d3';
} /* '' */
.uil-file-copy-alt:before {
    content: '\e9d4';
} /* '' */
.uil-file-download-alt:before {
    content: '\e9d5';
} /* '' */
.uil-file-download:before {
    content: '\e9d6';
} /* '' */
.uil-file-edit-alt:before {
    content: '\e9d7';
} /* '' */
.uil-file-exclamation-alt:before {
    content: '\e9d8';
} /* '' */
.uil-file-exclamation:before {
    content: '\e9d9';
} /* '' */
.uil-file-heart:before {
    content: '\e9da';
} /* '' */
.uil-file-info-alt:before {
    content: '\e9db';
} /* '' */
.uil-file-landscape-alt:before {
    content: '\e9dc';
} /* '' */
.uil-file-landscape:before {
    content: '\e9dd';
} /* '' */
.uil-file-lanscape-slash:before {
    content: '\e9de';
} /* '' */
.uil-file-lock-alt:before {
    content: '\e9df';
} /* '' */
.uil-file-medical-alt:before {
    content: '\e9e0';
} /* '' */
.uil-file-medical:before {
    content: '\e9e1';
} /* '' */
.uil-file-minus-alt:before {
    content: '\e9e2';
} /* '' */
.uil-file-minus:before {
    content: '\e9e3';
} /* '' */
.uil-file-network:before {
    content: '\e9e4';
} /* '' */
.uil-file-plus-alt:before {
    content: '\e9e5';
} /* '' */
.uil-file-plus:before {
    content: '\e9e6';
} /* '' */
.uil-file-question-alt:before {
    content: '\e9e7';
} /* '' */
.uil-file-question:before {
    content: '\e9e8';
} /* '' */
.uil-file-redo-alt:before {
    content: '\e9e9';
} /* '' */
.uil-file-search-alt:before {
    content: '\e9ea';
} /* '' */
.uil-file-share-alt:before {
    content: '\e9eb';
} /* '' */
.uil-file-shield-alt:before {
    content: '\e9ec';
} /* '' */
.uil-file-slash:before {
    content: '\e9ed';
} /* '' */
.uil-file-times-alt:before {
    content: '\e9ee';
} /* '' */
.uil-file-times:before {
    content: '\e9ef';
} /* '' */
.uil-file-upload-alt:before {
    content: '\e9f0';
} /* '' */
.uil-file-upload:before {
    content: '\e9f1';
} /* '' */
.uil-file:before {
    content: '\e9f2';
} /* '' */
.uil-files-landscapes-alt:before {
    content: '\e9f3';
} /* '' */
.uil-files-landscapes:before {
    content: '\e9f4';
} /* '' */
.uil-film:before {
    content: '\e9f5';
} /* '' */
.uil-filter-slash:before {
    content: '\e9f6';
} /* '' */
.uil-filter:before {
    content: '\e9f7';
} /* '' */
.uil-flask-potion:before {
    content: '\e9f8';
} /* '' */
.uil-flask:before {
    content: '\e9f9';
} /* '' */
.uil-flip-h-alt:before {
    content: '\e9fa';
} /* '' */
.uil-flip-h:before {
    content: '\e9fb';
} /* '' */
.uil-flip-v-alt:before {
    content: '\e9fc';
} /* '' */
.uil-flip-v:before {
    content: '\e9fd';
} /* '' */
.uil-flower:before {
    content: '\e9fe';
} /* '' */
.uil-focus-add:before {
    content: '\e9ff';
} /* '' */
.uil-focus-target:before {
    content: '\ea00';
} /* '' */
.uil-focus:before {
    content: '\ea01';
} /* '' */
.uil-folder-check:before {
    content: '\ea02';
} /* '' */
.uil-folder-download:before {
    content: '\ea03';
} /* '' */
.uil-folder-exclamation:before {
    content: '\ea04';
} /* '' */
.uil-folder-heart:before {
    content: '\ea05';
} /* '' */
.uil-folder-info:before {
    content: '\ea06';
} /* '' */
.uil-folder-lock:before {
    content: '\ea07';
} /* '' */
.uil-folder-medical:before {
    content: '\ea08';
} /* '' */
.uil-folder-minus:before {
    content: '\ea09';
} /* '' */
.uil-folder-network:before {
    content: '\ea0a';
} /* '' */
.uil-folder-plus:before {
    content: '\ea0b';
} /* '' */
.uil-folder-question:before {
    content: '\ea0c';
} /* '' */
.uil-folder-slash:before {
    content: '\ea0d';
} /* '' */
.uil-folder-times:before {
    content: '\ea0e';
} /* '' */
.uil-folder-upload:before {
    content: '\ea0f';
} /* '' */
.uil-folder:before {
    content: '\ea10';
} /* '' */
.uil-food:before {
    content: '\ea11';
} /* '' */
.uil-football-american:before {
    content: '\ea12';
} /* '' */
.uil-football-ball:before {
    content: '\ea13';
} /* '' */
.uil-football:before {
    content: '\ea14';
} /* '' */
.uil-forecastcloud-moon-tear:before {
    content: '\ea15';
} /* '' */
.uil-forwaded-call:before {
    content: '\ea16';
} /* '' */
.uil-forward:before {
    content: '\ea17';
} /* '' */
.uil-frown:before {
    content: '\ea18';
} /* '' */
.uil-game-structure:before {
    content: '\ea19';
} /* '' */
.uil-game:before {
    content: '\ea1a';
} /* '' */
.uil-gift:before {
    content: '\ea1b';
} /* '' */
.uil-github-alt:before {
    content: '\ea1c';
} /* '' */
.uil-github:before {
    content: '\ea1d';
} /* '' */
.uil-glass-martini-alt-slash:before {
    content: '\ea1e';
} /* '' */
.uil-glass-martini-alt:before {
    content: '\ea1f';
} /* '' */
.uil-glass-martini:before {
    content: '\ea20';
} /* '' */
.uil-glass-tea:before {
    content: '\ea21';
} /* '' */
.uil-glass:before {
    content: '\ea22';
} /* '' */
.uil-globe:before {
    content: '\ea23';
} /* '' */
.uil-gold:before {
    content: '\ea24';
} /* '' */
.uil-google-drive-alt:before {
    content: '\ea25';
} /* '' */
.uil-google-drive:before {
    content: '\ea26';
} /* '' */
.uil-google-hangouts:before {
    content: '\ea27';
} /* '' */
.uil-google-play:before {
    content: '\ea28';
} /* '' */
.uil-google:before {
    content: '\ea29';
} /* '' */
.uil-graduation-hat:before {
    content: '\ea2a';
} /* '' */
.uil-graph-bar:before {
    content: '\ea2b';
} /* '' */
.uil-grid:before {
    content: '\ea2c';
} /* '' */
.uil-grids:before {
    content: '\ea2d';
} /* '' */
.uil-grin-tongue-wink-alt:before {
    content: '\ea2e';
} /* '' */
.uil-grin-tongue-wink:before {
    content: '\ea2f';
} /* '' */
.uil-grin:before {
    content: '\ea30';
} /* '' */
.uil-grip-horizontal-line:before {
    content: '\ea31';
} /* '' */
.uil-hdd:before {
    content: '\ea32';
} /* '' */
.uil-headphones-alt:before {
    content: '\ea33';
} /* '' */
.uil-headphones:before {
    content: '\ea34';
} /* '' */
.uil-heart-alt:before {
    content: '\ea35';
} /* '' */
.uil-heart-medical:before {
    content: '\ea36';
} /* '' */
.uil-heart-rate:before {
    content: '\ea37';
} /* '' */
.uil-heart-sign:before {
    content: '\ea38';
} /* '' */
.uil-heart:before {
    content: '\ea39';
} /* '' */
.uil-heartbeat:before {
    content: '\ea3a';
} /* '' */
.uil-history-alt:before {
    content: '\ea3b';
} /* '' */
.uil-history:before {
    content: '\ea3c';
} /* '' */
.uil-home-alt:before {
    content: '\ea3d';
} /* '' */
.uil-home:before {
    content: '\ea3e';
} /* '' */
.uil-horizontal-align-center:before {
    content: '\ea3f';
} /* '' */
.uil-horizontal-align-left:before {
    content: '\ea40';
} /* '' */
.uil-horizontal-align-right:before {
    content: '\ea41';
} /* '' */
.uil-horizontal-distribution-center:before {
    content: '\ea42';
} /* '' */
.uil-horizontal-distribution-left:before {
    content: '\ea43';
} /* '' */
.uil-horizontal-distribution-right:before {
    content: '\ea44';
} /* '' */
.uil-hunting:before {
    content: '\ea45';
} /* '' */
.uil-image-alt-slash:before {
    content: '\ea46';
} /* '' */
.uil-image-block:before {
    content: '\ea47';
} /* '' */
.uil-image-broken:before {
    content: '\ea48';
} /* '' */
.uil-image-check:before {
    content: '\ea49';
} /* '' */
.uil-image-download:before {
    content: '\ea4a';
} /* '' */
.uil-image-edit:before {
    content: '\ea4b';
} /* '' */
.uil-image-lock:before {
    content: '\ea4c';
} /* '' */
.uil-image-minus:before {
    content: '\ea4d';
} /* '' */
.uil-image-plus:before {
    content: '\ea4e';
} /* '' */
.uil-image-question:before {
    content: '\ea4f';
} /* '' */
.uil-image-redo:before {
    content: '\ea50';
} /* '' */
.uil-image-resize-landscape:before {
    content: '\ea51';
} /* '' */
.uil-image-resize-square:before {
    content: '\ea52';
} /* '' */
.uil-image-search:before {
    content: '\ea53';
} /* '' */
.uil-image-share:before {
    content: '\ea54';
} /* '' */
.uil-image-shield:before {
    content: '\ea55';
} /* '' */
.uil-image-slash:before {
    content: '\ea56';
} /* '' */
.uil-image-times:before {
    content: '\ea57';
} /* '' */
.uil-image-upload:before {
    content: '\ea58';
} /* '' */
.uil-image-v:before {
    content: '\ea59';
} /* '' */
.uil-image:before {
    content: '\ea5a';
} /* '' */
.uil-images:before {
    content: '\ea5b';
} /* '' */
.uil-incoming-call:before {
    content: '\ea5c';
} /* '' */
.uil-info-circle:before {
    content: '\ea5d';
} /* '' */
.uil-instagram-alt:before {
    content: '\ea5e';
} /* '' */
.uil-instagram:before {
    content: '\ea5f';
} /* '' */
.uil-intercom:before {
    content: '\ea60';
} /* '' */
.uil-invoice:before {
    content: '\ea61';
} /* '' */
.uil-italic:before {
    content: '\ea62';
} /* '' */
.uil-jackhammer:before {
    content: '\ea63';
} /* '' */
.uil-java-script:before {
    content: '\ea64';
} /* '' */
.uil-kayak:before {
    content: '\ea65';
} /* '' */
.uil-key-skeleton-alt:before {
    content: '\ea66';
} /* '' */
.uil-key-skeleton:before {
    content: '\ea67';
} /* '' */
.uil-keyboard-alt:before {
    content: '\ea68';
} /* '' */
.uil-keyboard-hide:before {
    content: '\ea69';
} /* '' */
.uil-keyboard-show:before {
    content: '\ea6a';
} /* '' */
.uil-keyboard:before {
    content: '\ea6b';
} /* '' */
.uil-keyhole-circle:before {
    content: '\ea6c';
} /* '' */
.uil-keyhole-square-full:before {
    content: '\ea6d';
} /* '' */
.uil-keyhole-square:before {
    content: '\ea6e';
} /* '' */
.uil-kid:before {
    content: '\ea6f';
} /* '' */
.uil-label-alt:before {
    content: '\ea70';
} /* '' */
.uil-label:before {
    content: '\ea71';
} /* '' */
.uil-lamp:before {
    content: '\ea72';
} /* '' */
.uil-laptop-cloud:before {
    content: '\ea73';
} /* '' */
.uil-laptop:before {
    content: '\ea74';
} /* '' */
.uil-laughing:before {
    content: '\ea75';
} /* '' */
.uil-layer-group-slash:before {
    content: '\ea76';
} /* '' */
.uil-layer-group:before {
    content: '\ea77';
} /* '' */
.uil-layers-alt:before {
    content: '\ea78';
} /* '' */
.uil-layers-slash:before {
    content: '\ea79';
} /* '' */
.uil-layers:before {
    content: '\ea7a';
} /* '' */
.uil-left-arrow-from-left:before {
    content: '\ea7b';
} /* '' */
.uil-left-arrow-to-left:before {
    content: '\ea7c';
} /* '' */
.uil-left-indent-alt:before {
    content: '\ea7d';
} /* '' */
.uil-left-indent:before {
    content: '\ea7e';
} /* '' */
.uil-left-to-right-text-direction:before {
    content: '\ea7f';
} /* '' */
.uil-life-ring:before {
    content: '\ea80';
} /* '' */
.uil-lightbulb-alt:before {
    content: '\ea81';
} /* '' */
.uil-lightbulb:before {
    content: '\ea82';
} /* '' */
.uil-line-alt:before {
    content: '\ea83';
} /* '' */
.uil-line-spacing:before {
    content: '\ea84';
} /* '' */
.uil-line:before {
    content: '\ea85';
} /* '' */
.uil-link-alt:before {
    content: '\ea86';
} /* '' */
.uil-link-broken:before {
    content: '\ea87';
} /* '' */
.uil-link-h:before {
    content: '\ea88';
} /* '' */
.uil-link:before {
    content: '\ea89';
} /* '' */
.uil-linkedin-alt:before {
    content: '\ea8a';
} /* '' */
.uil-linkedin:before {
    content: '\ea8b';
} /* '' */
.uil-list-ui-alt:before {
    content: '\ea8c';
} /* '' */
.uil-list-ul:before {
    content: '\ea8d';
} /* '' */
.uil-location-arrow-alt:before {
    content: '\ea8e';
} /* '' */
.uil-location-arrow:before {
    content: '\ea8f';
} /* '' */
.uil-location-pin-alt:before {
    content: '\ea90';
} /* '' */
.uil-location-point:before {
    content: '\ea91';
} /* '' */
.uil-location:before {
    content: '\ea92';
} /* '' */
.uil-lock-access:before {
    content: '\ea93';
} /* '' */
.uil-lock-alt:before {
    content: '\ea94';
} /* '' */
.uil-lock-open-alt:before {
    content: '\ea95';
} /* '' */
.uil-lock-slash:before {
    content: '\ea96';
} /* '' */
.uil-lock:before {
    content: '\ea97';
} /* '' */
.uil-mailbox-alt:before {
    content: '\ea98';
} /* '' */
.uil-mailbox:before {
    content: '\ea99';
} /* '' */
.uil-map-marker-alt:before {
    content: '\ea9a';
} /* '' */
.uil-map-marker-edit:before {
    content: '\ea9b';
} /* '' */
.uil-map-marker-info:before {
    content: '\ea9c';
} /* '' */
.uil-map-marker-minus:before {
    content: '\ea9d';
} /* '' */
.uil-map-marker-plus:before {
    content: '\ea9e';
} /* '' */
.uil-map-marker-question:before {
    content: '\ea9f';
} /* '' */
.uil-map-marker-shield:before {
    content: '\eaa0';
} /* '' */
.uil-map-marker-slash:before {
    content: '\eaa1';
} /* '' */
.uil-map-marker:before {
    content: '\eaa2';
} /* '' */
.uil-map-pin-alt:before {
    content: '\eaa3';
} /* '' */
.uil-map-pin:before {
    content: '\eaa4';
} /* '' */
.uil-map:before {
    content: '\eaa5';
} /* '' */
.uil-mars:before {
    content: '\eaa6';
} /* '' */
.uil-maximize-left:before {
    content: '\eaa7';
} /* '' */
.uil-medal:before {
    content: '\eaa8';
} /* '' */
.uil-medical-drip:before {
    content: '\eaa9';
} /* '' */
.uil-medical-square-full:before {
    content: '\eaaa';
} /* '' */
.uil-medical-square:before {
    content: '\eaab';
} /* '' */
.uil-medical:before {
    content: '\eaac';
} /* '' */
.uil-medium-m:before {
    content: '\eaad';
} /* '' */
.uil-medkit:before {
    content: '\eaae';
} /* '' */
.uil-meeting-board:before {
    content: '\eaaf';
} /* '' */
.uil-meh-alt:before {
    content: '\eab0';
} /* '' */
.uil-meh-closed-eye:before {
    content: '\eab1';
} /* '' */
.uil-meh:before {
    content: '\eab2';
} /* '' */
.uil-message:before {
    content: '\eab3';
} /* '' */
.uil-microphone-slash:before {
    content: '\eab4';
} /* '' */
.uil-microphone:before {
    content: '\eab5';
} /* '' */
.uil-minus-circle:before {
    content: '\eab6';
} /* '' */
.uil-minus-path:before {
    content: '\eab7';
} /* '' */
.uil-minus-square-full:before {
    content: '\eab8';
} /* '' */
.uil-minus-square:before {
    content: '\eab9';
} /* '' */
.uil-minus:before {
    content: '\eaba';
} /* '' */
.uil-missed-call:before {
    content: '\eabb';
} /* '' */
.uil-mobey-bill-slash:before {
    content: '\eabc';
} /* '' */
.uil-mobile-android-alt:before {
    content: '\eabd';
} /* '' */
.uil-mobile-android:before {
    content: '\eabe';
} /* '' */
.uil-mobile-vibrate:before {
    content: '\eabf';
} /* '' */
.uil-modem:before {
    content: '\eac0';
} /* '' */
.uil-money-bill-stack:before {
    content: '\eac1';
} /* '' */
.uil-money-bill:before {
    content: '\eac2';
} /* '' */
.uil-money-insert:before {
    content: '\eac3';
} /* '' */
.uil-money-stack:before {
    content: '\eac4';
} /* '' */
.uil-money-withdraw:before {
    content: '\eac5';
} /* '' */
.uil-money-withdrawal:before {
    content: '\eac6';
} /* '' */
.uil-moneybag-alt:before {
    content: '\eac7';
} /* '' */
.uil-moneybag:before {
    content: '\eac8';
} /* '' */
.uil-monitor-heart-rate:before {
    content: '\eac9';
} /* '' */
.uil-monitor:before {
    content: '\eaca';
} /* '' */
.uil-moon-eclipse:before {
    content: '\eacb';
} /* '' */
.uil-moon:before {
    content: '\eacc';
} /* '' */
.uil-moonset:before {
    content: '\eacd';
} /* '' */
.uil-mountains-sun:before {
    content: '\eace';
} /* '' */
.uil-mountains:before {
    content: '\eacf';
} /* '' */
.uil-mouse-alt:before {
    content: '\ead0';
} /* '' */
.uil-mouse:before {
    content: '\ead1';
} /* '' */
.uil-multiply:before {
    content: '\ead2';
} /* '' */
.uil-music-note:before {
    content: '\ead3';
} /* '' */
.uil-music-tune-slash:before {
    content: '\ead4';
} /* '' */
.uil-music:before {
    content: '\ead5';
} /* '' */
.uil-n-a:before {
    content: '\ead6';
} /* '' */
.uil-navigator:before {
    content: '\ead7';
} /* '' */
.uil-nerd:before {
    content: '\ead8';
} /* '' */
.uil-newspaper:before {
    content: '\ead9';
} /* '' */
.uil-ninja:before {
    content: '\eada';
} /* '' */
.uil-no-entry:before {
    content: '\eadb';
} /* '' */
.uil-notebooks:before {
    content: '\eadc';
} /* '' */
.uil-notes:before {
    content: '\eadd';
} /* '' */
.uil-object-group:before {
    content: '\eade';
} /* '' */
.uil-object-ungroup:before {
    content: '\eadf';
} /* '' */
.uil-octagon:before {
    content: '\eae0';
} /* '' */
.uil-outgoing-call:before {
    content: '\eae1';
} /* '' */
.uil-package:before {
    content: '\eae2';
} /* '' */
.uil-padlock:before {
    content: '\eae3';
} /* '' */
.uil-paint-tool:before {
    content: '\eae4';
} /* '' */
.uil-palette:before {
    content: '\eae5';
} /* '' */
.uil-panorama-h-alt:before {
    content: '\eae6';
} /* '' */
.uil-panorama-h:before {
    content: '\eae7';
} /* '' */
.uil-panorama-v:before {
    content: '\eae8';
} /* '' */
.uil-paperclip:before {
    content: '\eae9';
} /* '' */
.uil-paragraph:before {
    content: '\eaea';
} /* '' */
.uil-parcel:before {
    content: '\eaeb';
} /* '' */
.uil-parking-square:before {
    content: '\eaec';
} /* '' */
.uil-pathfinder-unite:before {
    content: '\eaed';
} /* '' */
.uil-pathfinder:before {
    content: '\eaee';
} /* '' */
.uil-pause-circle:before {
    content: '\eaef';
} /* '' */
.uil-pause:before {
    content: '\eaf0';
} /* '' */
.uil-paypal:before {
    content: '\eaf1';
} /* '' */
.uil-pen:before {
    content: '\eaf2';
} /* '' */
.uil-pentagon:before {
    content: '\eaf3';
} /* '' */
.uil-phone-alt:before {
    content: '\eaf4';
} /* '' */
.uil-phone-pause:before {
    content: '\eaf5';
} /* '' */
.uil-phone-slash:before {
    content: '\eaf6';
} /* '' */
.uil-phone-times:before {
    content: '\eaf7';
} /* '' */
.uil-phone-volume:before {
    content: '\eaf8';
} /* '' */
.uil-phone:before {
    content: '\eaf9';
} /* '' */
.uil-picture:before {
    content: '\eafa';
} /* '' */
.uil-plane-arrival:before {
    content: '\eafb';
} /* '' */
.uil-plane-departure:before {
    content: '\eafc';
} /* '' */
.uil-plane-fly:before {
    content: '\eafd';
} /* '' */
.uil-plane:before {
    content: '\eafe';
} /* '' */
.uil-play-circle:before {
    content: '\eaff';
} /* '' */
.uil-play:before {
    content: '\eb00';
} /* '' */
.uil-plug:before {
    content: '\eb01';
} /* '' */
.uil-plus-circle:before {
    content: '\eb02';
} /* '' */
.uil-plus-square:before {
    content: '\eb03';
} /* '' */
.uil-plus:before {
    content: '\eb04';
} /* '' */
.uil-podium:before {
    content: '\eb05';
} /* '' */
.uil-polygon:before {
    content: '\eb06';
} /* '' */
.uil-post-stamp:before {
    content: '\eb07';
} /* '' */
.uil-postcard:before {
    content: '\eb08';
} /* '' */
.uil-pound-circle:before {
    content: '\eb09';
} /* '' */
.uil-pound:before {
    content: '\eb0a';
} /* '' */
.uil-power:before {
    content: '\eb0b';
} /* '' */
.uil-prescription-bottle:before {
    content: '\eb0c';
} /* '' */
.uil-presentation-check:before {
    content: '\eb0d';
} /* '' */
.uil-presentation-edit:before {
    content: '\eb0e';
} /* '' */
.uil-presentation-line:before {
    content: '\eb0f';
} /* '' */
.uil-presentation-lines-alt:before {
    content: '\eb10';
} /* '' */
.uil-presentation-minus:before {
    content: '\eb11';
} /* '' */
.uil-presentation-play:before {
    content: '\eb12';
} /* '' */
.uil-presentation-plus:before {
    content: '\eb13';
} /* '' */
.uil-presentation-times:before {
    content: '\eb14';
} /* '' */
.uil-presentation:before {
    content: '\eb15';
} /* '' */
.uil-previous:before {
    content: '\eb16';
} /* '' */
.uil-pricetag-alt:before {
    content: '\eb17';
} /* '' */
.uil-print-slash:before {
    content: '\eb18';
} /* '' */
.uil-print:before {
    content: '\eb19';
} /* '' */
.uil-processor:before {
    content: '\eb1a';
} /* '' */
.uil-pump:before {
    content: '\eb1b';
} /* '' */
.uil-puzzle-piece:before {
    content: '\eb1c';
} /* '' */
.uil-question-circle:before {
    content: '\eb1d';
} /* '' */
.uil-raddit-alien-alt:before {
    content: '\eb1e';
} /* '' */
.uil-rainbow:before {
    content: '\eb1f';
} /* '' */
.uil-raindrops-alt:before {
    content: '\eb20';
} /* '' */
.uil-raindrops:before {
    content: '\eb21';
} /* '' */
.uil-receipt-alt:before {
    content: '\eb22';
} /* '' */
.uil-receipt:before {
    content: '\eb23';
} /* '' */
.uil-record-audio:before {
    content: '\eb24';
} /* '' */
.uil-redo:before {
    content: '\eb25';
} /* '' */
.uil-refresh:before {
    content: '\eb26';
} /* '' */
.uil-registered:before {
    content: '\eb27';
} /* '' */
.uil-repeat:before {
    content: '\eb28';
} /* '' */
.uil-restaurant:before {
    content: '\eb29';
} /* '' */
.uil-right-indent-alt:before {
    content: '\eb2a';
} /* '' */
.uil-right-to-left-text-direction:before {
    content: '\eb2b';
} /* '' */
.uil-robot:before {
    content: '\eb2c';
} /* '' */
.uil-rope-way:before {
    content: '\eb2d';
} /* '' */
.uil-rotate-360:before {
    content: '\eb2e';
} /* '' */
.uil-rss-alt:before {
    content: '\eb2f';
} /* '' */
.uil-rss-interface:before {
    content: '\eb30';
} /* '' */
.uil-rss:before {
    content: '\eb31';
} /* '' */
.uil-ruler-combined:before {
    content: '\eb32';
} /* '' */
.uil-ruler:before {
    content: '\eb33';
} /* '' */
.uil-sad-cry:before {
    content: '\eb34';
} /* '' */
.uil-sad-crying:before {
    content: '\eb35';
} /* '' */
.uil-sad-dizzy:before {
    content: '\eb36';
} /* '' */
.uil-sad-squint:before {
    content: '\eb37';
} /* '' */
.uil-sad:before {
    content: '\eb38';
} /* '' */
.uil-scaling-left:before {
    content: '\eb39';
} /* '' */
.uil-scaling-right:before {
    content: '\eb3a';
} /* '' */
.uil-scenery:before {
    content: '\eb3b';
} /* '' */
.uil-schedule:before {
    content: '\eb3c';
} /* '' */
.uil-science:before {
    content: '\eb3d';
} /* '' */
.uil-screw:before {
    content: '\eb3e';
} /* '' */
.uil-scroll-h:before {
    content: '\eb3f';
} /* '' */
.uil-scroll:before {
    content: '\eb40';
} /* '' */
.uil-search-alt:before {
    content: '\eb41';
} /* '' */
.uil-search-minus:before {
    content: '\eb42';
} /* '' */
.uil-search-plus:before {
    content: '\eb43';
} /* '' */
.uil-search:before {
    content: '\eb44';
} /* '' */
.uil-selfie:before {
    content: '\eb45';
} /* '' */
.uil-server-alt:before {
    content: '\eb46';
} /* '' */
.uil-server-connection:before {
    content: '\eb47';
} /* '' */
.uil-server-network-alt:before {
    content: '\eb48';
} /* '' */
.uil-server-network:before {
    content: '\eb49';
} /* '' */
.uil-server:before {
    content: '\eb4a';
} /* '' */
.uil-servers:before {
    content: '\eb4b';
} /* '' */
.uil-servicemark:before {
    content: '\eb4c';
} /* '' */
.uil-share-alt:before {
    content: '\eb4d';
} /* '' */
.uil-shield-check:before {
    content: '\eb4e';
} /* '' */
.uil-shield-exclamation:before {
    content: '\eb4f';
} /* '' */
.uil-shield-question:before {
    content: '\eb50';
} /* '' */
.uil-shield-slash:before {
    content: '\eb51';
} /* '' */
.uil-shield:before {
    content: '\eb52';
} /* '' */
.uil-ship:before {
    content: '\eb53';
} /* '' */
.uil-shop:before {
    content: '\eb54';
} /* '' */
.uil-shopping-basket:before {
    content: '\eb55';
} /* '' */
.uil-shopping-cart-alt:before {
    content: '\eb56';
} /* '' */
.uil-shopping-trolley:before {
    content: '\eb57';
} /* '' */
.uil-shovel:before {
    content: '\eb58';
} /* '' */
.uil-shrink:before {
    content: '\eb59';
} /* '' */
.uil-shuffle:before {
    content: '\eb5a';
} /* '' */
.uil-shutter-alt:before {
    content: '\eb5b';
} /* '' */
.uil-shutter:before {
    content: '\eb5c';
} /* '' */
.uil-sick:before {
    content: '\eb5d';
} /* '' */
.uil-sigma:before {
    content: '\eb5e';
} /* '' */
.uil-sign-alt:before {
    content: '\eb5f';
} /* '' */
.uil-sign-in-alt:before {
    content: '\eb60';
} /* '' */
.uil-sign-left:before {
    content: '\eb61';
} /* '' */
.uil-sign-out-alt:before {
    content: '\eb62';
} /* '' */
.uil-sign-right:before {
    content: '\eb63';
} /* '' */
.uil-signal-alt-3:before {
    content: '\eb64';
} /* '' */
.uil-signal-alt:before {
    content: '\eb65';
} /* '' */
.uil-signal:before {
    content: '\eb66';
} /* '' */
.uil-silence:before {
    content: '\eb67';
} /* '' */
.uil-silent-squint:before {
    content: '\eb68';
} /* '' */
.uil-sim-card:before {
    content: '\eb69';
} /* '' */
.uil-sitemap:before {
    content: '\eb6a';
} /* '' */
.uil-skip-forward-alt:before {
    content: '\eb6b';
} /* '' */
.uil-skip-forward-circle:before {
    content: '\eb6c';
} /* '' */
.uil-skip-forward:before {
    content: '\eb6d';
} /* '' */
.uil-slack-alt:before {
    content: '\eb6e';
} /* '' */
.uil-slack:before {
    content: '\eb6f';
} /* '' */
.uil-sliders-v-alt:before {
    content: '\eb70';
} /* '' */
.uil-sliders-v:before {
    content: '\eb71';
} /* '' */
.uil-smile-beam:before {
    content: '\eb72';
} /* '' */
.uil-smile-dizzy:before {
    content: '\eb73';
} /* '' */
.uil-smile-squint-wink-alt:before {
    content: '\eb74';
} /* '' */
.uil-smile-squint-wink:before {
    content: '\eb75';
} /* '' */
.uil-smile-wink-alt:before {
    content: '\eb76';
} /* '' */
.uil-smile-wink:before {
    content: '\eb77';
} /* '' */
.uil-smile:before {
    content: '\eb78';
} /* '' */
.uil-snapchat-ghost:before {
    content: '\eb79';
} /* '' */
.uil-snapchat-square:before {
    content: '\eb7a';
} /* '' */
.uil-snow-flake:before {
    content: '\eb7b';
} /* '' */
.uil-snowflake-alt:before {
    content: '\eb7c';
} /* '' */
.uil-snowflake:before {
    content: '\eb7d';
} /* '' */
.uil-sort-amount-down:before {
    content: '\eb7e';
} /* '' */
.uil-sort-amount-up:before {
    content: '\eb7f';
} /* '' */
.uil-sort:before {
    content: '\eb80';
} /* '' */
.uil-sorting:before {
    content: '\eb81';
} /* '' */
.uil-space-key:before {
    content: '\eb82';
} /* '' */
.uil-spade:before {
    content: '\eb83';
} /* '' */
.uil-sperms:before {
    content: '\eb84';
} /* '' */
.uil-spin:before {
    content: '\eb85';
} /* '' */
.uil-sport:before {
    content: '\eb86';
} /* '' */
.uil-square-full:before {
    content: '\eb87';
} /* '' */
.uil-square-shape:before {
    content: '\eb88';
} /* '' */
.uil-square:before {
    content: '\eb89';
} /* '' */
.uil-squint:before {
    content: '\eb8a';
} /* '' */
.uil-star-half-alt:before {
    content: '\eb8b';
} /* '' */
.uil-star:before {
    content: '\eb8c';
} /* '' */
.uil-step-backward-alt:before {
    content: '\eb8d';
} /* '' */
.uil-step-backward-circle:before {
    content: '\eb8e';
} /* '' */
.uil-step-backward:before {
    content: '\eb8f';
} /* '' */
.uil-step-forward:before {
    content: '\eb90';
} /* '' */
.uil-stop-circle:before {
    content: '\eb91';
} /* '' */
.uil-stopwatch-slash:before {
    content: '\eb92';
} /* '' */
.uil-stopwatch:before {
    content: '\eb93';
} /* '' */
.uil-store-alt:before {
    content: '\eb94';
} /* '' */
.uil-store:before {
    content: '\eb95';
} /* '' */
.uil-streering:before {
    content: '\eb96';
} /* '' */
.uil-stretcher:before {
    content: '\eb97';
} /* '' */
.uil-subject:before {
    content: '\eb98';
} /* '' */
.uil-subway-alt:before {
    content: '\eb99';
} /* '' */
.uil-subway:before {
    content: '\eb9a';
} /* '' */
.uil-suitcase-alt:before {
    content: '\eb9b';
} /* '' */
.uil-suitcase:before {
    content: '\eb9c';
} /* '' */
.uil-sun:before {
    content: '\eb9d';
} /* '' */
.uil-sunset:before {
    content: '\eb9e';
} /* '' */
.uil-surprise:before {
    content: '\eb9f';
} /* '' */
.uil-swatchbook:before {
    content: '\eba0';
} /* '' */
.uil-swimmer:before {
    content: '\eba1';
} /* '' */
.uil-symbol:before {
    content: '\eba2';
} /* '' */
.uil-sync-exclamation:before {
    content: '\eba3';
} /* '' */
.uil-sync-slash:before {
    content: '\eba4';
} /* '' */
.uil-sync:before {
    content: '\eba5';
} /* '' */
.uil-syringe:before {
    content: '\eba6';
} /* '' */
.uil-table:before {
    content: '\eba7';
} /* '' */
.uil-tablet:before {
    content: '\eba8';
} /* '' */
.uil-tablets:before {
    content: '\eba9';
} /* '' */
.uil-tachometer-fast:before {
    content: '\ebaa';
} /* '' */
.uil-tag-alt:before {
    content: '\ebab';
} /* '' */
.uil-tag:before {
    content: '\ebac';
} /* '' */
.uil-tape:before {
    content: '\ebad';
} /* '' */
.uil-taxi:before {
    content: '\ebae';
} /* '' */
.uil-tear:before {
    content: '\ebaf';
} /* '' */
.uil-technology:before {
    content: '\ebb0';
} /* '' */
.uil-telescope:before {
    content: '\ebb1';
} /* '' */
.uil-temperature-empty:before {
    content: '\ebb2';
} /* '' */
.uil-temperature-half:before {
    content: '\ebb3';
} /* '' */
.uil-temperature-minus:before {
    content: '\ebb4';
} /* '' */
.uil-temperature-plus:before {
    content: '\ebb5';
} /* '' */
.uil-temperature-quarter:before {
    content: '\ebb6';
} /* '' */
.uil-temperature-three-quarter:before {
    content: '\ebb7';
} /* '' */
.uil-temperature:before {
    content: '\ebb8';
} /* '' */
.uil-text-fields:before {
    content: '\ebb9';
} /* '' */
.uil-text-size:before {
    content: '\ebba';
} /* '' */
.uil-text-strike-through:before {
    content: '\ebbb';
} /* '' */
.uil-text:before {
    content: '\ebbc';
} /* '' */
.uil-th-large:before {
    content: '\ebbd';
} /* '' */
.uil-th-slash:before {
    content: '\ebbe';
} /* '' */
.uil-th:before {
    content: '\ebbf';
} /* '' */
.uil-thermometer:before {
    content: '\ebc0';
} /* '' */
.uil-thumbs-down:before {
    content: '\ebc1';
} /* '' */
.uil-thumbs-up:before {
    content: '\ebc2';
} /* '' */
.uil-thunderstorm-moon:before {
    content: '\ebc3';
} /* '' */
.uil-thunderstorm-sun:before {
    content: '\ebc4';
} /* '' */
.uil-thunderstorm:before {
    content: '\ebc5';
} /* '' */
.uil-ticket:before {
    content: '\ebc6';
} /* '' */
.uil-times-circle:before {
    content: '\ebc7';
} /* '' */
.uil-times-square:before {
    content: '\ebc8';
} /* '' */
.uil-times:before {
    content: '\ebc9';
} /* '' */
.uil-toggle-off:before {
    content: '\ebca';
} /* '' */
.uil-toggle-on:before {
    content: '\ebcb';
} /* '' */
.uil-top-arrow-from-top:before {
    content: '\ebcc';
} /* '' */
.uil-top-arrow-to-top:before {
    content: '\ebcd';
} /* '' */
.uil-tornado:before {
    content: '\ebce';
} /* '' */
.uil-trademark-circle:before {
    content: '\ebcf';
} /* '' */
.uil-trademark:before {
    content: '\ebd0';
} /* '' */
.uil-traffic-barrier:before {
    content: '\ebd1';
} /* '' */
.uil-trash-alt:before {
    content: '\ebd2';
} /* '' */
.uil-trash:before {
    content: '\ebd3';
} /* '' */
.uil-trees:before {
    content: '\ebd4';
} /* '' */
.uil-triangle:before {
    content: '\ebd5';
} /* '' */
.uil-trophy:before {
    content: '\ebd6';
} /* '' */
.uil-trowel:before {
    content: '\ebd7';
} /* '' */
.uil-truck-case:before {
    content: '\ebd8';
} /* '' */
.uil-truck-loading:before {
    content: '\ebd9';
} /* '' */
.uil-truck:before {
    content: '\ebda';
} /* '' */
.uil-tumblr-square:before {
    content: '\ebdb';
} /* '' */
.uil-tumblr:before {
    content: '\ebdc';
} /* '' */
.uil-tv-retro-slash:before {
    content: '\ebdd';
} /* '' */
.uil-tv-retro:before {
    content: '\ebde';
} /* '' */
.uil-twitter:before {
    content: '\ebdf';
} /* '' */
.uil-umbrella:before {
    content: '\ebe0';
} /* '' */
.uil-unamused:before {
    content: '\ebe1';
} /* '' */
.uil-underline:before {
    content: '\ebe2';
} /* '' */
.uil-unlock-alt:before {
    content: '\ebe3';
} /* '' */
.uil-unlock:before {
    content: '\ebe4';
} /* '' */
.uil-upload-alt:before {
    content: '\ebe5';
} /* '' */
.uil-upload:before {
    content: '\ebe6';
} /* '' */
.uil-usd-circle:before {
    content: '\ebe7';
} /* '' */
.uil-usd-square:before {
    content: '\ebe8';
} /* '' */
.uil-user-check:before {
    content: '\ebe9';
} /* '' */
.uil-user-circle:before {
    content: '\ebea';
} /* '' */
.uil-user-exclamation:before {
    content: '\ebeb';
} /* '' */
.uil-user-hard-hat:before {
    content: '\ebec';
} /* '' */
.uil-user-minus:before {
    content: '\ebed';
} /* '' */
.uil-user-plus:before {
    content: '\ebee';
} /* '' */
.uil-user-square:before {
    content: '\ebef';
} /* '' */
.uil-user-times:before {
    content: '\ebf0';
} /* '' */
.uil-user:before {
    content: '\ebf1';
} /* '' */
.uil-users-alt:before {
    content: '\ebf2';
} /* '' */
.uil-utensils-alt:before {
    content: '\ebf3';
} /* '' */
.uil-utensils:before {
    content: '\ebf4';
} /* '' */
.uil-vector-square-alt:before {
    content: '\ebf5';
} /* '' */
.uil-vector-square:before {
    content: '\ebf6';
} /* '' */
.uil-venus:before {
    content: '\ebf7';
} /* '' */
.uil-vertical-align-bottom:before {
    content: '\ebf8';
} /* '' */
.uil-vertical-align-center:before {
    content: '\ebf9';
} /* '' */
.uil-vertical-align-top:before {
    content: '\ebfa';
} /* '' */
.uil-vertical-distribute-bottom:before {
    content: '\ebfb';
} /* '' */
.uil-vertical-distribution-center:before {
    content: '\ebfc';
} /* '' */
.uil-vertical-distribution-top:before {
    content: '\ebfd';
} /* '' */
.uil-video-slash:before {
    content: '\ebfe';
} /* '' */
.uil-video:before {
    content: '\ebff';
} /* '' */
.uil-visual-studio:before {
    content: '\ec00';
} /* '' */
.uil-voicemail-rectangle:before {
    content: '\ec01';
} /* '' */
.uil-voicemail:before {
    content: '\ec02';
} /* '' */
.uil-volleyball:before {
    content: '\ec03';
} /* '' */
.uil-volume-down:before {
    content: '\ec04';
} /* '' */
.uil-volume-mute:before {
    content: '\ec05';
} /* '' */
.uil-volume-off:before {
    content: '\ec06';
} /* '' */
.uil-volume-up:before {
    content: '\ec07';
} /* '' */
.uil-volume:before {
    content: '\ec08';
} /* '' */
.uil-vuejs-alt:before {
    content: '\ec09';
} /* '' */
.uil-vuejs:before {
    content: '\ec0a';
} /* '' */
.uil-wall:before {
    content: '\ec0b';
} /* '' */
.uil-wallet:before {
    content: '\ec0c';
} /* '' */
.uil-watch-alt:before {
    content: '\ec0d';
} /* '' */
.uil-watch:before {
    content: '\ec0e';
} /* '' */
.uil-water-drop-slash:before {
    content: '\ec0f';
} /* '' */
.uil-water-glass:before {
    content: '\ec10';
} /* '' */
.uil-water:before {
    content: '\ec11';
} /* '' */
.uil-web-grid-alt:before {
    content: '\ec12';
} /* '' */
.uil-web-grid:before {
    content: '\ec13';
} /* '' */
.uil-web-section-alt:before {
    content: '\ec14';
} /* '' */
.uil-web-section:before {
    content: '\ec15';
} /* '' */
.uil-webcam:before {
    content: '\ec16';
} /* '' */
.uil-weight:before {
    content: '\ec17';
} /* '' */
.uil-whatsapp:before {
    content: '\ec18';
} /* '' */
.uil-wheel-barrow:before {
    content: '\ec19';
} /* '' */
.uil-wheelchair-alt:before {
    content: '\ec1a';
} /* '' */
.uil-wheelchair:before {
    content: '\ec1b';
} /* '' */
.uil-wifi-router:before {
    content: '\ec1c';
} /* '' */
.uil-wifi-slash:before {
    content: '\ec1d';
} /* '' */
.uil-wifi:before {
    content: '\ec1e';
} /* '' */
.uil-wind:before {
    content: '\ec1f';
} /* '' */
.uil-window-grid:before {
    content: '\ec20';
} /* '' */
.uil-window-maximize:before {
    content: '\ec21';
} /* '' */
.uil-window-restore:before {
    content: '\ec22';
} /* '' */
.uil-window-section:before {
    content: '\ec23';
} /* '' */
.uil-window:before {
    content: '\ec24';
} /* '' */
.uil-windsock:before {
    content: '\ec25';
} /* '' */
.uil-wrap-text:before {
    content: '\ec26';
} /* '' */
.uil-wrench:before {
    content: '\ec27';
} /* '' */
.uil-yellow:before {
    content: '\ec28';
} /* '' */
.uil-yen-circle:before {
    content: '\ec29';
} /* '' */
.uil-yen:before {
    content: '\ec2a';
} /* '' */
.uil-youtube-alt:before {
    content: '\ec2b';
} /* '' */
.uil-youtube:before {
    content: '\ec2c';
} /* '' */
