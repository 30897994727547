//
// custom-forms.scss
//

@each $color, $value in $theme-colors {
    .form-checkbox-#{$color},
    .form-radio-#{$color} {
        .form-check-input {
            &:checked {
                background-color: $value;
                border-color: $value;
            }
        }
    }
}

.form-switch-lg {
    padding-left: $form-switch-padding-start;

    .form-check-input {
        width: $form-switch-width-lg;
        height: $form-switch-height-lg;
        margin-top: 15px;
        margin-left: $form-switch-padding-start * -1;
        margin-right: $form-switch-padding-start;
        background-image: escape-svg($form-switch-bg-image);
        background-position: left center;
        @include border-radius($form-switch-border-radius);
        @include transition($form-switch-transition);

        &:focus {
            background-image: escape-svg($form-switch-focus-bg-image);
        }

        &:checked {
            background-position: $form-switch-checked-bg-position;

            @if $enable-gradients {
                background-image: escape-svg($form-switch-checked-bg-image), var(--#{$variable-prefix}gradient);
            } @else {
                background-image: escape-svg($form-switch-checked-bg-image);
            }
        }
    }
}
