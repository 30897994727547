.cookie_bar {
  margin: 25px;
  padding: 5px 10px;
  font-family: SansSerif, sans-serif;
}

.cookie_container {
  align-items: baseline;
  background-color: $white;
  border-top: 2px solid $primary;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 99999;
}
