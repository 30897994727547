.energy_box  {
  display: flex !important;
  align-items: center !important;
  height: $topbar-height;
}

.energy_box_border {
  border: 1px solid $primary;
  box-shadow: 0px 1px 4px 1px $primary;
  background: none;
  margin-top: 5px;
  margin-bottom: 5px;
}
