// Background lighten

@each $color, $value in $theme-colors {
    .bg-#{$color}-lighten {
        @include bg-variant-light($value);
    }
}

.bg-footer {
    background-color: #3A444E;

    a {
        color: #39afd1;
    }
}

// Dark Mode
body[data-layout-color='dark'] {
    @each $color, $value in $dark-theme-colors {
        // bg-color
        .bg-#{$color} {
            background-color: $value !important;
        }
        .bg-#{$color}-lighten {
            background-color: rgba($value, 0.25) !important;
        }
    }
}
